<template>
	<div class="info-container">
		<div class="form-item2">
			<div>真实姓名</div>
			<el-input v-model="ruleForm.trueName"></el-input>
		</div>
		<div class="form-item2">
			<div>学校</div>
			<el-select style="width:100%" v-model="ruleForm.schoolId" filterable remote reserve-keyword
				placeholder="请输入关键词" :remote-method="searchSchool" :loading="loading">
				<el-option v-for="item in schoolOption" :key="item.id" :label="item.name"
					:value="item.id">
				</el-option>
			</el-select>
		</div>
		<div class="form-item2">
			<div>学段年级</div>
			<div class="sectionAndGradeTree">
				<el-tree class="tree-border" default-expand-all @check="treeCheckChange" check-strictly
					:data="sectionGradeTree" show-checkbox ref="sectionGrade" node-key="id"
					empty-text="加载中，请稍候" :props="defaultProps"></el-tree>
			</div>
		</div>
		<div class="form-item2">
			<div>学科</div>
			<el-select style="width:100%" v-model="ruleForm.subjectId" placeholder="请选择">
				<el-option v-for="item in subjectOption" :label="item.label" :value="item.value"
					:key="item.value"></el-option>
			</el-select>
		</div>
		<!-- <div style="display: flex;">
			<div class="form-item1">
				<div>学科</div>
				<el-select v-model="info.subject" placeholder="请选择">
					<el-option v-for="(item, index) in subjectList" :key="index" :label="item"
						:value="item"></el-option>
				</el-select>
			</div>
			<div class="form-item1">
				<div>学段</div>
				<el-select v-model="info.grade" placeholder="请选择">
					<el-option label="小学一年级" value="小学一年级"></el-option>
					<el-option label="小学二年级" value="小学二年级"></el-option>
					<el-option label="小学三年级" value="小学三年级"></el-option>
					<el-option label="小学四年级" value="小学四年级"></el-option>
					<el-option label="小学五年级" value="小学五年级"></el-option>
					<el-option label="小学六年级" value="小学六年级"></el-option>
					<el-option label="初中七年级" value="初中七年级"></el-option>
					<el-option label="初中八年级" value="初中八年级"></el-option>
					<el-option label="初中九年级" value="初中九年级"></el-option>
					<el-option label="高中一年级" value="高中一年级"></el-option>
					<el-option label="高中二年级" value="高中二年级"></el-option>
					<el-option label="高中三年级" value="高中三年级"></el-option>
					<el-option label="职成" value="职成"></el-option>
					<el-option label="综合" value="综合"></el-option>
				</el-select>
			</div>
		</div>
		<div class="form-item2">
			<div>真实姓名</div>
			<el-input v-model="info.trueName"></el-input>
		</div>
		<div class="form-item2">
			<div>手机</div>
			<el-input v-model="info.phone"></el-input>
		</div>
		<div class="form-item2">
			<div>QQ号</div>
			<el-input v-model="info.qqNumber"></el-input>
		</div>
		<div class="form-item2">
			<div>邮箱</div>
			<el-input v-model="info.mail"></el-input>
		</div>
		<div class="form-item2">
			<div>简介</div>
			<el-input type="textarea" v-model="info.introduction"></el-input>
		</div> -->
		<div style="text-align: right;padding-right: 100px;padding-top: 20px">
			<el-button type="primary" @click="createSpecial">保存</el-button>
		</div>
	</div>
</template>

<script>
	import {
		subjectOptionApi,
		userSelfEditApi,
		schoolListApi,
		userInfo,
		gradeTreeApi
	} from '@/api/newApi';
	export default {
		name: "info",
		props: {
			// userInfo: {}
		},
		data() {
			return {
				loading:false,
				sectionOption: [],
				gradeOption: [],
				gradeFormOption: [],
				subjectOption: [],
				schoolOption: [],
				userInfo: {},
				sectionGradeTree: [],
				defaultProps: {
					children: "children",
					label: "name"
				},
				subjectList: [
					'语文', '数学', '英语', '化学', '生物', '物理', '历史', '地理', '体育', '政治', '音乐', '美术', '科学',
					'信息技术', '道德与法治', '综合实践活动', '心理', '德育', '教育科研', '幼儿教育', '劳动技术教育', '研究性学习',
					'通用技术', '党建干训', '书法', '网络研修', '中学劳技', '信息应用技术', '其他'
				],
				ruleForm:{
					gradeIdChecked:undefined,
					trueName: undefined,
					schoolId: undefined,
					subjectId: undefined,
					sectionId: undefined,
					gradeId: undefined,
				},
				sectionGradeTree: [],
				defaultProps: {
					children: "children",
					label: "name"
				},
				// info: {
				// 	subject: '',
				// 	grade: '',
				// 	trueName: '',
				// 	phone: '',
				// 	qqNumber: '',
				// 	mail: '',
				// 	introduction: '',
				// 	headPortrait: ''
				// }
			}
		},
		mounted() {
			this.getUserInfo();
			
			// Object.assign(this.info, this.userInfo)
		},
		methods: {
			//勾选树结构
			treeCheckChange(data, checked) {
				console.log(data, checked);
				this.ruleForm.gradeIdChecked = checked.checkedKeys.join(',');
			},
			//搜索学校
			searchSchool(query) {
				if (query !== '') {
					this.loading = true
					this.getSchoolbyKey(query).then(res => {
						this.loading = false;
						this.schoolOption = res.result.rows;
					})
				} else {
					this.schoolOption = [];
				}
			},
			//获取学校
			getSchoolbyKey(keyword) {
				return new Promise((resolve, reject) => {
					schoolListApi({
						name: keyword,
						limit: 500
					}).then(res => {
						resolve(res)
					}).catch(err => {
						reject(err);
					})
				})
			},
			//获取用户信息
			getUserInfo(){
				userInfo().then(res=>{
					this.userInfo = res.result;
					this.getSubjectOption().then(resChild => {
						this.subjectOption = resChild.result;
					})
					this.gettreeOption().then(resChild => {
						this.sectionGradeTree = resChild.result;
					})
					this.ruleForm.trueName = res.result.trueName;
					if (res.result.schoolName && res.result.schoolId) {
						this.getSchoolbyKey(res.result.schoolName).then(resChild => {
							this.schoolOption = resChild.result.rows;
							this.ruleForm.schoolId = res.result.schoolId;
						})
					}
					this.ruleForm.subjectId = res.result.subjectId ? res.result.subjectId : undefined;
					if (res.result.gradeIdChecked) {
						this.$nextTick(() => {
							this.$refs.sectionGrade.setCheckedKeys(res.result.gradeIdChecked.split(','));
						})
					}
				})
			},
			//获取学科
			getSubjectOption() {
				return new Promise((resolve, reject) => {
					subjectOptionApi().then(res => {
						resolve(res);
					}).catch(err => {
						reject(err);
					})
				})
			},
			//获取学段年级树
			gettreeOption() {
				return new Promise((resolve, reject) => {
					gradeTreeApi().then(res => {
						resolve(res);
					}).catch(err => {
						reject(err);
					})
				})
			},
			createSpecial() {
				if(!this.ruleForm.trueName){
					this.$message({
						type: 'error',
						message: '请输入真实姓名'
					});
					return;
				}
				if(!this.ruleForm.schoolId){
					this.$message({
						type: 'error',
						message: '请选择学校'
					});
					return;
				}
				if(!this.ruleForm.gradeIdChecked){
					this.$message({
						type: 'error',
						message: '请选择学段年级'
					});
					return;
				}
				if(!this.ruleForm.subjectId){
					this.$message({
						type: 'error',
						message: '请选择学科'
					});
					return;
				}
				userSelfEditApi(this.ruleForm).then(res => {
					this.getUserInfo();
					this.$message({
						type: 'success',
						message: '保存成功'
					});
				})
				// this.$api.home.createSpecial({
				// 	...this.info
				// }).then(res => {
				// 	if (res.code == 200) {
				// 		this.$message({
				// 			message: '保存成功',
				// 			type: 'success'
				// 		})
				// 	}
				// })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.info-container {
		border-right: 1px solid #ccc;
		padding: 50px 0 50px 50px;

		.form-item1 {
			width: 180px;
			margin-right: 35px;
			text-align: left;

			&>div {
				padding: 5px 0;
			}
		}

		.form-item2 {
			width: 427px;
			text-align: left;

			&>div {
				padding: 5px 0;
			}
		}
	}
	.sectionAndGradeTree{
		height:300px;
		overflow-y: auto;
		border:1px solid #e5e6e7;
	}
</style>